import { ReactComponent as CloseIcon } from "@assets/icons/x-close.svg";
import Input from "@components/Input";
import useKeywordsFromWorkflowKeywords from "@screens/workflow/studio/hooks/useKeywordsFromWorkflowKeywords";
import { titleCase } from "@utils/utils";
import { useState } from "react";
import Tooltip from "src/components/Tooltip";

export default function InputList({
  query,
  keywords,
  close,
}: {
  query: string;
  keywords: ReturnType<typeof useKeywordsFromWorkflowKeywords>;
  close?: () => void;
}) {
  const [searchQuery, setSearchQuery] = useState("");

  const predictorList = Object.keys(keywords.sources).reduce((prev, k) => {
    prev[k] = (keywords?.sources[k] ?? [])?.filter((i) =>
      i.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return prev;
  }, {} as Record<string, string[]>);
  const policyList =
    Object.keys(keywords.policies)?.map((p) => {
      return {
        name: p,
        output: keywords.policies[p].filter((o) =>
          o.toLowerCase().includes(searchQuery.toLowerCase())
        ),
      };
    }) || [];
  const workflowList =
    Object.keys(keywords.workflows)?.map((p) => {
      return {
        name: p,
        output: keywords.workflows[p].filter((o) =>
          o.toLowerCase().includes(searchQuery.toLowerCase())
        ),
      };
    }) || [];
  const functions =
    Object.keys(keywords.functions).filter((p) =>
      p.toLowerCase().includes(searchQuery.toLowerCase())
    ) || [];

  const inputs = keywords.inputs?.filter((i) =>
    i.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div className="font-b2-medium flex items-center justify-between pb-1.5">
        Inputs
        <Input
          placeholder="Search"
          className="!h-6 ml-auto mr-4"
          inputClassName="!h-6"
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {close && <CloseIcon onClick={() => close()} />}
      </div>
      <InputItem key="function" items={functions} name="Functions" />
      {Object.keys(predictorList).map((key) => {
        const items = predictorList[key];
        return (
          <InputItem
            key={key}
            items={items.filter((k) => k.includes(query))}
            name={key}
          />
        );
      })}
      {policyList.map((policy) => {
        const items = policy.output;
        return (
          <InputItem
            isPolicy
            key={policy.name}
            items={items.filter((k) => k.includes(query))}
            name={policy.name}
          />
        );
      })}
      {workflowList.map((wf) => {
        const items = wf.output;
        return (
          <InputItem
            isPolicy
            key={wf.name}
            items={items.filter((k) => k.includes(query))}
            name={wf.name}
          />
        );
      })}
      <InputItem
        isPolicy
        key="input"
        items={inputs?.filter((k) => k.includes(query))}
        name="Inputs"
      />
    </>
  );
}

function InputItem({
  name,
  items,
  isPolicy,
}: {
  name: string;
  items: string[];
  isPolicy?: boolean;
}) {
  const [isExpanded, setIsExpanded] = useState(false);

  if (items.length === 0) return null;

  return (
    <div className="font-code font-b1 w-full transition-all max-h-fit">
      <div
        onClick={() => setIsExpanded((p) => !p)}
        className="font-b1-bold mb-2 flex gap-1 items-center cursor-pointer bg-white w-full z-10"
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={!isExpanded ? "-rotate-90" : "rotate-0"}
        >
          <path
            d="M9 4.5L6 7.5L3 4.5"
            stroke="#000"
            strokeWidth="1.33"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <span>
          {isPolicy ? name : titleCase(name)} ({items.length})
        </span>
      </div>
      {isExpanded && (
        <div className="[&>div]:w-full flex flex-col gap-1 mb-3">
          {items.map((item) => (
            <Tooltip
              center
              tooltipClassName="left-[16px]"
              content={
                <div className="relative px-4 py-1 cursor-pointer" key={item}>
                  <div className="max-w-[300px] truncate">{item}</div>
                </div>
              }
              tooltipContent={item}
            />
          ))}
        </div>
      )}
    </div>
  );
}
